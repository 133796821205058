<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <b-overlay
                    variant="white"
                    :show="showLoading"
                    spinner-variant="primary"
                    blur="0"
                    opacity=".75"
                    rounded="sm"
                >
                    <div class="p-1">
                        <form class="" @submit.prevent="submitForm">
                            <div class="row">
                                <div class="form-group col-12 col-md-4">
                                    <label for="phone24">Nombre:</label>
                                    <b-form-input v-model="responsible_manager.name" autocomplete="off"/>
                                    <span v-if="validation_responsible_manager.name" class="text-danger">
                                      {{ validation_responsible_manager.name }}
                                    </span>
                                </div>
                                <div class="form-group col-12 col-md-4">
                                  <label for="phone24">Email:</label>
                                  <b-form-input v-model="responsible_manager.email" autocomplete="off"/>
                                  <span v-if="validation_responsible_manager.email" class="text-danger">
                                        {{ validation_responsible_manager.email }}
                                      </span>
                                </div>
                              <div class="form-group col-12 col-md-4">
                                <label for="phone24">Teléfono:</label>
                                <b-form-input v-model="responsible_manager.phone" autocomplete="off"/>
                                <span v-if="validation_responsible_manager.phone" class="text-danger">
                                      {{ validation_responsible_manager.phone }}
                                    </span>
                              </div>
                              <div class="form-group col-12">
                                <label for="phone24">Observaciones:</label>
                                <b-form-input v-model="responsible_manager.observations" autocomplete="off"/>
                                <span v-if="validation_responsible_manager.observations" class="text-danger">
                                      {{ validation_responsible_manager.observations }}
                                    </span>
                              </div>

                            </div>
                            <div class="row">
                                <button type="submit" class="btn btn-primary col-12 col-md-1 ml-1 mt-2">
                                    Guardar
                                </button>
                            </div>
                        </form>
                    </div>
                </b-overlay>
            </b-card>
        </b-col>
    </b-row>
</template>
<script>
import {BCard, BCol, BOverlay, BRow, BFormInput} from 'bootstrap-vue'
import {mapState} from "vuex"
import {VHE_RESPONSIBLE_MANAGER_LIST} from "@/router/web/constants"
import {createHelpers} from "vuex-map-fields"

import helper from "@/resources/helpers/helpers"

const {mapFields} = createHelpers({
    getterType: 'auxiliaries2ResponsibleManagerVhe/getField',
    mutationType: 'auxiliaries2ResponsibleManagerVhe/updateField',
})

export default {
    name: "FormView",
    components: {
        BCol,
        BRow,
        BCard,
        BFormInput,
        BOverlay,
    },
    data() {
        return {
            edit: false,
            showLoading: false,
        }
    },
    computed: {
        ...mapState('auxiliaries2ResponsibleManagerVhe', ['validation_responsible_manager']),
        ...mapFields({
          responsible_manager: 'responsible_manager',
        }),
    },
    created() {
        this.$store.commit('auxiliaries2ResponsibleManagerVhe/RESET_STATE')
        this.$store.commit('auxiliaries2ResponsibleManagerVhe/CLEAR_VALIDATION')

        if (this.$route.params.id) {
            this.edit = true
            this.getResponsibleManager()
        } else {
            this.edit = false
        }
        this.$root.$refs.auxiliariesResponsibleManagerForm = this
    },
    methods: {
      getResponsibleManager() {

            this.showLoading = true

            this.$store.dispatch('auxiliaries2ResponsibleManagerVhe/findResponsibleManager', {
                id: this.$route.params.id ?? this.responsible_manager.id
            })
                .then(response => {
                  this.showLoading = false
                })
                .catch(error => {
                    this.showLoading = false
                })
        },
        cancel() {
            this.$router.push(VHE_RESPONSIBLE_MANAGER_LIST)
        },
        createAuxiliar() {
            this.$store.dispatch('auxiliaries2ResponsibleManagerVhe/createResponsibleManager', this.responsible_manager)
                .then(response => {
                    helper.showToast('Responsable creado correctamente!', 'CheckIcon', 'success')
                    this.$router.replace(VHE_RESPONSIBLE_MANAGER_LIST)
                })
                .catch(error => {
                    helper.showToast('Error al crear responsable', 'AlertOctagonIcon', 'danger')
                    console.log(error)
                })
        },
        updateAuxiliar() {
            this.$store.dispatch('auxiliaries2ResponsibleManagerVhe/updateResponsibleManager', this.responsible_manager)
                .then(response => {
                    helper.showToast('Responsable editado correctamente!', 'CheckIcon', 'success')
                    this.$router.replace(VHE_RESPONSIBLE_MANAGER_LIST)
                })
                .catch(error => {
                    helper.showToast('Error al editar responsable', 'AlertOctagonIcon', 'danger')
                    console.log(error)
                })
        },
        submitForm() {
            if (this.$route.params.id) {
                this.updateAuxiliar()
            } else {
                this.createAuxiliar()
            }
        },
    },
}
</script>
